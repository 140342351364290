var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Length:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":" | cm"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.value.length),callback:function ($$v) {_vm.$set(_vm.value, "length", $$v)},expression:"value.length"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Width:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Width","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":" | cm"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.value.width),callback:function ($$v) {_vm.$set(_vm.value, "width", $$v)},expression:"value.width"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Weight:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":" | kg"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.value.weight),callback:function ($$v) {_vm.$set(_vm.value, "weight", $$v)},expression:"value.weight"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Height:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":" | cm"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":""},model:{value:(_vm.value.height),callback:function ($$v) {_vm.$set(_vm.value, "height", $$v)},expression:"value.height"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }