<template>
  <div>
    <b-form-group
      :label="$t('Trademark')+`:`"
      label-cols-md="12"
    >
      <v-select
        v-if="trademarkOptions"
        v-model="brand_id"
        label="name"
        :reduce="x => x.id"
        :options="trademarkOptions"
      />
    </b-form-group>
    <b-form-group
      :label="$t('Country of origin')+`:`"
      label-cols-md="12"
    >
      <v-select
        v-if="originOptions"
        v-model="origin_id"
        label="name"
        :reduce="x => x.id"
        :options="originOptions"
      />
    </b-form-group>
    <b-form-group
      :label="$t('Supplier')+`:`"
      label-cols-md="12"
    >
      <v-select
        v-if="supplierOptions"
        v-model="supplier_id"
        label="name"
        :reduce="x => x.id"
        :options="supplierOptions"
      />
    </b-form-group>
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
import { BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import general from '@/mixins/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    categoryIdErrorText: {
      type: String,
      default: null,
    },
    trademarkOptions: {
      type: Array,
      default() {
        return []
      },
    },
    originOptions: {
      type: Array,
      default() {
        return []
      },
    },
    supplierOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isShow: false,
      brand_id: null,
      origin_id: null,
      supplier_id: null,
      required,
    }
  },
  watch: {
    brand_id(val) {
      if (val) {
        this.$emit('changeBrand', val)
      }
    },
    origin_id(val) {
      if (val) {
        this.$emit('changeOrigin', val)
      }
    },
    supplier_id(val) {
      if (val) {
        this.$emit('changeSupplier', val)
      }
    },
    trademarkOptions(val) {
      if (val) {
        const newBrand = val.filter(
          value => value.slug === this.value.brand_slug,
        )[0]
        if (newBrand !== undefined) {
          this.brand_id = newBrand
        } else {
          this.brand_id = val.filter(
            value => value.id === this.value.brand_id,
          )[0]
        }
      }
    },
    originOptions(val) {
      if (val) {
        this.origin_id = val.filter(
          value => value.slug === this.value.origin_slug,
        )[0]
      }
    },
    supplierOptions(val) {
      if (val) {
        this.supplier_id = val.filter(
          value => value.slug === this.value.supplier_slug,
        )[0]
      }
    },
  },
  async mounted() {
    this.isShow = true
  },
}
</script>
