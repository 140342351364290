<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="`*`+$t('Price')+`:`"
          label-cols-md="12"
        >
          <validation-provider
            #default="{ errors }"
            name="Price"
            rules="required"
          >
            <b-form-input
              v-model="value.price"
              :state="errors.length > 0 ? false : null"
              type="number"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="* Quantity:"
          label-cols-md="12"
        >
          <validation-provider
            #default="{ errors }"
            name="Quantity"
            rules="required"
          >
            <b-form-input
              v-model="value.quantity"
              :state="errors.length > 0 ? false : null"
              type="number"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- <b-form-checkbox
            v-model="value.sell_a_sLot"
            value="sellAsLot"
            class="custom-control-primary mt-1"
          >
            Sell as lot
          </b-form-checkbox> -->
        </b-form-group>
      </b-col>
    </b-row>
    <Variation v-model="value" />
    <!-- <Currency v-model="value" /> -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  // BInputGroup,
  // BFormCheckbox,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Variation from './components/Variation.vue'
// import Currency from './components/Currency.vue'
// import WhoseSale from '@/views/pages/product/product-edit/components/WhoseSale.vue'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    // BInputGroup,
    // BFormCheckbox,
    Variation,
    // Currency,
    // WhoseSale,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      email,
      returnShippingFeePaidOptions: [
        { title: 'Buyer' },
        { title: 'Seller (Free Returns)' },
      ],
      returnTimesOptions: [{ title: '30 days' }, { title: '60 days' }],
    }
  },
  methods: {
    onEditorChange({ html }) {
      this.value.product_description = html
      this.product_description_length = html.length
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
