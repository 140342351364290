<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <Overplay v-if="isShow" />
    <div v-if="enableVariation >= 1">
      <b-form-group :label="$t('Variation')+ ` 1:`" label-cols-md="12">
        <div class="bg-gray">
          <b-form-group :label="$t('Name')" label-cols-md="12">
            <v-select
              v-if="firstAttributeList"
              v-model="ware_house_temp[0]"
              label="name_type"
              :options="firstAttributeList"
              placeholder="Enter Variation Name, eg: Color"
              @input="handleChooseVariation(1)"
            />
          </b-form-group>
          <b-form-group :label="$t('Options')" label-cols-md="12">
            <b-form class="repeater-form" @submit.prevent="repeateAgain(1)">
              <div
                v-for="(item, index) in listVariant_1"
                :id="String(index)"
                :key="index"
                class="d-flex align-items-center mb-1"
              >
                <v-select
                  v-model="listVariant_1[index]"
                  label="name"
                  :options="firstAttributeOptions"
                  class="w-100 mr-3"
                  placeholder="Enter Variation Name, eg: Color"
                  @input="handlerShowTableVariation"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeItem(1, index)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-25" />
                </b-button>
              </div>
            </b-form>
          </b-form-group>
          <b-form-group
            v-if="firstAttributeOptions.length > 0"
            label-cols-md="12"
            label=""
          >
            <div
              v-if="listVariant_1.length < firstAttributeOptionsTemp.length"
              class="fade-browse"
              @click="repeateAgain(1)"
            >
              <feather-icon icon="PlusCircleIcon" class="mr-25" />
              {{ $t('Add options') }} ({{ listVariant_1.length }}/{{
                firstAttributeOptionsTemp.length
              }})
            </div>
          </b-form-group>
        </div>
      </b-form-group>
    </div>
    <b-form-group
      v-if="enableVariation >= 2"
      :label="$t('Variation')+ ` 2:`"
      label-cols-md="12"
    >
      <div class="bg-gray">
        <b-form-group :label="$t('Name')" label-cols-md="12">
          <v-select
            v-if="secondAttributeList"
            v-model="ware_house_temp[1]"
            label="name_type"
            :options="secondAttributeList"
            placeholder="Enter Variation Name, eg: Color"
            @input="handleChooseVariation(2)"
          />
        </b-form-group>
        <b-form-group :label="$t('Options')" label-cols-md="12">
          <b-form class="repeater-form" @submit.prevent="repeateAgain(2)">
            <div
              v-for="(item, index) in listVariant_2"
              :id="String(index)"
              :key="index"
              class="d-flex align-items-center mb-1"
            >
              <v-select
                v-model="listVariant_2[index]"
                label="name"
                :options="secondAttributeOptions"
                class="w-100 mr-3"
                placeholder="Enter Variation Name, eg: Color"
                @input="handlerShowTableVariation"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon rounded-circle"
                @click="removeItem(2, index)"
              >
                <feather-icon icon="Trash2Icon" class="mr-25" />
              </b-button>
            </div>
          </b-form>
        </b-form-group>
        <b-form-group
          v-if="secondAttributeOptions.length > 0"
          label-cols-md="12"
          label=""
        >
          <div
            v-if="listVariant_2.length < secondAttributeOptionsTemp.length"
            class="fade-browse"
            @click="repeateAgain(2)"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-25" />
            {{ $t('Add options') }} ({{ listVariant_2.length }}/{{
              secondAttributeOptionsTemp.length
            }})
          </div>
        </b-form-group>
      </div>
    </b-form-group>
    <b-form-group
      v-if="enableVariation >= 3"
      :label="$t('Variation')+ ` 3:`"
      label-cols-md="12"
    >
      <div class="bg-gray">
        <b-form-group :label="$t('Name')" label-cols-md="12">
          <v-select
            v-if="thirdAttributeList"
            v-model="ware_house_temp[2]"
            label="name_type"
            :options="thirdAttributeList"
            placeholder="Enter Variation Name, eg: Color"
            @input="handleChooseVariation(3)"
          />
        </b-form-group>
        <b-form-group :label="$t('Options')" label-cols-md="12">
          <b-form class="repeater-form" @submit.prevent="repeateAgain(3)">
            <div
              v-for="(item, index) in listVariant_3"
              :id="String(index)"
              :key="index"
              class="d-flex align-items-center mb-1"
            >
              <v-select
                v-model="listVariant_3[index]"
                label="name"
                :options="thirdAttributeOptions"
                class="w-100 mr-3"
                placeholder="Enter Variation Name, eg: Color"
                @input="handlerShowTableVariation"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon rounded-circle"
                @click="removeItem(3, index)"
              >
                <feather-icon icon="Trash2Icon" class="mr-25" />
              </b-button>
            </div>
          </b-form>
        </b-form-group>
        <b-form-group
          v-if="thirdAttributeOptions.length > 0"
          label-cols-md="12"
          label=""
        >
          <div
            v-if="listVariant_3.length < thirdAttributeOptionsTemp.length"
            class="fade-browse"
            @click="repeateAgain(3)"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-25" />
            {{ $t('Add options') }} ({{ listVariant_3.length }}/{{
              thirdAttributeOptionsTemp.length
            }})
          </div>
        </b-form-group>
      </div>
    </b-form-group>
    <b-form-group
      v-if="enableVariation < 3"
      :label="`*`+ $t('Variation')+ ` ${Number(enableVariation)+ 1}`+`:`"
      label-cols-md="12"
    >
      <div class="fade-browse" @click="enableVariation += 1">
        <feather-icon icon="PlusCircleIcon" class="mr-25" />
        {{ $t('Enable variation') }}
      </div>
    </b-form-group>
    <b-form-group
      v-if="listTable && listTable.length > 0"
      :label="$t('Variation list') +`:`"
      label-cols-md="12"
    >
      <b-table :fields="fields" :items="listTable">
        <template #cell(price)="data">
          <b-input-group prepend="$ | " class="input-group-merge">
            <b-form-input
              v-model="data.item.price"
              type="number"
              :placeholder="$t('Enter price')"
              @input="handlerShowErr"
            />
          </b-input-group>
          <small v-if="data.item.price_error" class="text-danger">{{
            data.item.price_error
          }}</small>
        </template>
        <template #cell(quantity)="data">
          <b-form-input
            v-model="data.item.quantity"
            :readonly="
              !value.oldIsWareHouse && value.is_warehouse ? false : true
            "
            type="number"
            class="w-100"
            :placeholder="$t('Enter the number of quantity')"
          />
        </template>
        <template #cell(sku)="data">
          <b-form-input
            v-model="data.item.sku"
            :placeholder="$t('Enter the SKU')"
            class="w-100"
            @input="handlerShowErr"
          />
          <small v-if="data.item.sku_error" class="text-danger">{{
            data.item.sku_error
          }}</small>
        </template>
        <template #cell(is_active)="data">
          <b-form-checkbox
            v-model="data.item.is_active"
            checked="true"
            class="custom-control-success"
            name="check-button"
            switch
          />
        </template>
      </b-table>
    </b-form-group>
    <b-form-group :label="$t('Mẹo phân loại hàng hoá')" label-cols-md="12">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="table-cell wpx-150 bradius-br">
            <b-form-input
              class="input-contrl"
              type="number"
              :placeholder="$t('Price')"
            />
          </div>
          <div class="table-cell wpx-150 bradius-br">
            <b-form-input
              class="input-contrl"
              :placeholder="$t('Quantity')"
            />
          </div>
          <div class="table-cell wpx-150 bradius-br">
            <b-form-input
              class="input-contrl"
              :placeholder="$t('SKU')"
            />
          </div>
        </div>
        <div>
          <b-button
            variant="primary"
            type="submit"
            class="ml-4"
            @click.prevent="validationForm"
          >
            {{ $t('Applies to all variants') }}
          </b-button>
        </div>
      </div>
    </b-form-group>
    <!-- Tuỳ theo số variant đƯợc kích hoạt mà thay đổi giao diện tương ứng -->
    <div class="edit-main">
      <div class="d-flex">
        <div class="table-cell wpx-100 table-header">
          Màu
        </div>
        <div class="table-cell wpx-100 table-header">
          Size
        </div>
        <div class="table-cell wpx-100 table-header">
          Chất Liệu
        </div>
        <div class="table-cell wpx-100 table-header">
          Giá
        </div>
        <div class="table-cell wpx-100 table-header">
          SL
        </div>
        <div class="table-cell wpx-100 table-header">
          SKU
        </div>
        <div class="table-cell wpx-100 table-header">
          Trạng thái
        </div>
      </div>
      <div class="d-flex">
        <div class="table-cell wpx-100">
          <span>Xanh</span>
        </div>
        <div>
          <div class="d-flex">
            <div class="wpx-100 table-cell">
              M
            </div>
            <div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Cotton
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Thun
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="wpx-100 table-cell">
              L
            </div>
            <div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Cotton
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Thun
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="table-cell wpx-100">
          <span>Đỏ</span>
        </div>
        <div>
          <div class="d-flex">
            <div class="wpx-100 table-cell">
              M
            </div>
            <div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Cotton
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Thun
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="wpx-100 table-cell">
              L
            </div>
            <div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Cotton
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
              <div class="table-cell">
                <div class="table-cell wpx-100">
                  Thun
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    type="number"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-input
                    class="input-contrl"
                    :placeholder="$t('Nhập vào')"
                    @input="handlerShowTableVariation"
                  />
                </div>
                <div class="table-cell wpx-100">
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-expressions */

import {
  BButton,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  components: {
    BButton,
    BFormGroup,
    BForm,
    vSelect,
    BTable,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: false,
      enableVariation: 0,
      variation1Model: null,
      firstAttributeList: [],
      firstAttributeOptions: [],
      firstAttributeOptionsTemp: [],
      listVariant_1: [],
      variation2Model: null,
      secondAttributeList: [],
      secondAttributeOptions: [],
      secondAttributeOptionsTemp: [],
      listVariant_2: [],
      variation3Model: null,
      thirdAttributeList: [],
      thirdAttributeOptions: [],
      thirdAttributeOptionsTemp: [],
      listVariant_3: [],
      fields: [],
      listTable: [],
      ware_house_temp: [],
      listTableOld: [],
    }
  },
  watch: {
    'value.is_warehouse'(val) {
      this.loadVariation(this.value)
    },
    value(val) {
      if (val) {
        this.loadVariation(val)
      }
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.loadFirstAttributeList()
    this.loadSecondAttributeList()
    this.loadThirdAttributeList()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async loadVariation(val) {
      try {
        let resFirstAttribute = null
        let resSeccondAttribute = null
        let resThirdAttribute = null
        this.isShow = true
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        if (val.parent_warehouse.first !== undefined) {
          // Load detail of Variants
          resFirstAttribute = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/first_attribute/tree?site_id=${siteId}&name_type=${val.parent_warehouse.first}`,
          )
          if (resFirstAttribute.status === 200) {
            this.ware_house_temp[0] = resFirstAttribute.data.data[0]
            this.firstAttributeOptions = resFirstAttribute.data.data[0].list
            this.firstAttributeOptionsTemp = resFirstAttribute.data.data[0].list
            this.enableVariation = 1
          }
        }
        if (val.parent_warehouse.second !== undefined) {
          resSeccondAttribute = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/second_attribute/tree?site_id=${siteId}&name_type=${val.parent_warehouse.second}`,
          )
          if (resSeccondAttribute.status === 200) {
            this.ware_house_temp[1] = resSeccondAttribute.data.data[0]
            this.secondAttributeOptions = resSeccondAttribute.data.data[0].list
            this.secondAttributeOptionsTemp =
              resSeccondAttribute.data.data[0].list
            this.enableVariation = 2
          }
        }
        if (val.parent_warehouse.third !== undefined) {
          resThirdAttribute = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/third_attribute/tree?site_id=${siteId}&name_type=${val.parent_warehouse.third}`,
          )
          if (resThirdAttribute.status === 200) {
            this.ware_house_temp[2] = resThirdAttribute.data.data[0]
            this.thirdAttributeOptions = resThirdAttribute.data.data[0].list
            this.thirdAttributeOptionsTemp = resThirdAttribute.data.data[0].list
            this.enableVariation = 3
          }
        }
        // Load Variants of product
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/warehouses?site_id=${siteId}&product_id=${val.id}&is_attribute=true`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.value.ware_house_old = []
            if (res.data.data.att.first_attribute_id !== undefined) {
              this.listVariant_1 = res.data.data.att.first_attribute_id
            }
            if (res.data.data.att.second_attribute_id !== undefined) {
              this.listVariant_2 = res.data.data.att.second_attribute_id
            }
            if (res.data.data.att.third_attribute_id !== undefined) {
              this.listVariant_3 = res.data.data.att.third_attribute_id
            }
            res.data.data.items.map(item => {
              // this.value.warehouse_delete.push(item.id)
              const x = {
                id: item.id,
                avatar: item.avatar,
                is_warehouse: item.is_warehouse,
                price: item.price,
                quantity: item.quantity,
                is_active: Boolean(item.is_active === 1 ? true : false),
                sku: item.sku,
                name_temp: '',
                is_default: item.is_default,
              }
              if (
                this.value.parent_warehouse.first !== undefined &&
                item.name_att1 !== null
              ) {
                x.first_attribute_id = item.first_attribute_id
                x.first_attribute_name = item.name_att1
                x.first_attribute_name_type = item.name_type_att1
                x.name_temp = `${item.name_att1.toUpperCase()}`
              }
              if (
                this.value.parent_warehouse.second !== undefined &&
                item.name_att1 !== null &&
                item.name_att2 !== null
              ) {
                x.second_attribute_id = item.second_attribute_id
                x.second_attribute_name = item.name_att2
                x.second_attribute_name_type = item.name_type_att2
                x.name_temp = `${item.name_att1.toUpperCase()}-${item.name_att2.toUpperCase()}`
              }
              if (
                this.value.parent_warehouse.third !== undefined &&
                item.name_att1 !== null &&
                item.name_att2 !== null &&
                item.name_att3 !== null
              ) {
                x.third_attribute_id = item.third_attribute_id
                x.third_attribute_name = item.name_att3
                x.third_attribute_name_type = item.name_type_att3
                x.name_temp = `${item.name_att1.toUpperCase()}-${item.name_att2.toUpperCase()}-${item.name_att3.toUpperCase()}`
              }
              this.value.ware_house_old.push(x)
              this.listTableOld.push(x)
            })
            this.handlerShowTableVariation()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.isShow = false
      }
    },
    repeateAgain(variationNumber) {
      if (variationNumber === 1 && this.firstAttributeOptions.length <= 0) {
        return
      }
      if (variationNumber === 2 && this.secondAttributeOptions.length <= 0) {
        return
      }
      if (variationNumber === 3 && this.thirdAttributeOptions.length <= 0) {
        return
      }
      this[`listVariant_${variationNumber}`].push('')
      this.handlerShowTableVariation()
    },
    removeItem(variationNumber, index) {
      if (this[`listVariant_${variationNumber}`].length === 1) {
        this[`listVariant_${variationNumber}`] = []
        if (variationNumber === 1) {
          this.listVariant_1 = []
          this.firstAttributeOptions = []
          this.listVariant_2 = []
          this.secondAttributeOptions = []
          this.listVariant_3 = []
          this.thirdAttributeOptions = []
          this.ware_house_temp = []
        }
        if (variationNumber === 2) {
          this.listVariant_2 = []
          this.secondAttributeOptions = []
          this.listVariant_3 = []
          this.thirdAttributeOptions = []
          this.ware_house_temp.splice(2, 1)
          this.ware_house_temp.splice(1, 1)
        }
        if (variationNumber === 3) {
          this.listVariant_3 = []
          this.thirdAttributeOptions = []
          this.ware_house_temp.splice(2, 1)
        }
        this.enableVariation = variationNumber - 1
      } else {
        this[`listVariant_${variationNumber}`].splice(index, 1)
      }
      this.handlerShowTableVariation()
    },
    handlerShowErr() {
      const array = this.listTable
      if (this.listTableOld && this.listTableOld.length > 0) {
        this.listTable = []
        array.map(val => {
          const filterData = this.listTableOld.filter(
            el => el.name_temp === val.name_temp,
          )
          if (filterData && filterData.length > 0) {
            if (filterData[0].sku === null || filterData[0].sku === '') {
              filterData[0].sku_error = 'Vui lòng nhập SKU'
            } else {
              delete filterData[0].sku_error
            }
            if (filterData[0].price === null || filterData[0].price === '') {
              filterData[0].price_error = 'Vui lòng nhập giá tiền'
            } else {
              delete filterData[0].price_error
            }
            this.listTable.push(filterData[0])
          } else {
            const item = { ...val }
            if (item.sku === null || item.sku === '') {
              item.sku_error = 'Vui lòng nhập SKU'
            } else {
              delete item.sku_error
            }
            if (item.price === null || item.price === '') {
              item.price_error = 'Vui lòng nhập giá tiền'
            } else {
              delete item.price_error
            }
            this.listTable.push(item)
          }
        })
      } else {
        this.listTable = array
      }

      // Set params to create
      this.value.ware_house = this.listTable
    },
    handlerShowTableVariation() {
      // this.listTableOld = [...this.listTable]
      this.handlerFilterAttribute()
      this.fields = []
      if (this.listVariant_1 && this.listVariant_1.length > 0) {
        this.ware_house_temp[0].list_choose = this.listVariant_1
        this.fields.push({
          key: 'first_attribute_name',
          label: this.ware_house_temp[0].name_type,
        })
      }
      if (this.listVariant_2 && this.listVariant_2.length > 0) {
        this.ware_house_temp[1].list_choose = this.listVariant_2
        this.fields.push({
          key: 'second_attribute_name',
          label: this.ware_house_temp[1].name_type,
        })
      }
      if (this.listVariant_3 && this.listVariant_3.length > 0) {
        this.ware_house_temp[2].list_choose = this.listVariant_3
        this.fields.push({
          key: 'third_attribute_name',
          label: this.ware_house_temp[2].name_type,
        })
      }
      this.fields.push({ key: 'price', label: 'Price' })
      if (this.value.is_warehouse) {
        this.fields.push({ key: 'quantity', label: 'Quantity' })
      }
      this.fields.push({ key: 'sku', label: 'SKU' })
      this.fields.push({ key: 'is_active', label: 'Active' })

      const array = this.variants(this.ware_house_temp)
      if (this.listTableOld && this.listTableOld.length > 0) {
        this.listTable = []
        array.map(val => {
          const filterData = this.listTableOld.filter(
            el => el.name_temp === val.name_temp,
          )
          if (filterData && filterData.length > 0) {
            if (filterData[0].sku === null || filterData[0].sku === '') {
              filterData[0].sku_error = 'Vui lòng nhập SKU'
            } else {
              delete filterData[0].sku_error
            }
            if (filterData[0].price === null || filterData[0].price === '') {
              filterData[0].price_error = 'Vui lòng nhập giá tiền'
            } else {
              delete filterData[0].price_error
            }
            this.listTable.push(filterData[0])
          } else {
            const item = { ...val }
            if (item.sku === null || item.sku === '') {
              item.sku_error = 'Vui lòng nhập SKU'
            } else {
              delete item.sku_error
            }
            if (item.price === null || item.price === '') {
              item.price_error = 'Vui lòng nhập giá tiền'
            } else {
              delete item.price_error
            }
            this.listTable.push(item)
          }
        })
      } else {
        this.listTable = array
      }

      // Set params to create
      this.value.ware_house = this.listTable
      this.isShow = false
    },
    variants(options) {
      const array = []
      let sets = [[]]
      const objId = {}
      options.forEach(option => {
        const newSets = []
        option.list_choose.forEach(({ id, name }) => {
          newSets.push(Array.from(sets, set => [...set, name]))
          objId[name] = {
            id_attribute: option.id,
            name_type: option.name_type,
            id_option: id,
            name_option: name,
          }
        })
        sets = newSets.flatMap(set => set)
      })
      // Add more here
      sets.map(set => {
        const item = {
          avatar: null,
          is_warehouse: true,
          price: null,
          quantity: 0,
          is_active: true,
          sku: null,
          is_default: false,
          name_temp: set.join('-').toUpperCase(),
        }
        if (objId[set[0]] && objId[set[0]].id_option !== undefined) {
          item.first_attribute_id = objId[set[0]].id_option
        }
        if (objId[set[1]] && objId[set[1]].id_option !== undefined) {
          item.second_attribute_id = objId[set[1]].id_option
        }
        if (objId[set[2]] && objId[set[2]].id_option !== undefined) {
          item.third_attribute_id = objId[set[2]].id_option
        }
        if (objId[set[0]] && objId[set[0]].name_option !== undefined) {
          item.first_attribute_name = objId[set[0]].name_option
        }
        if (objId[set[1]] && objId[set[1]].name_option !== undefined) {
          item.second_attribute_name = objId[set[1]].name_option
        }

        if (objId[set[2]] && objId[set[2]].name_option !== undefined) {
          item.third_attribute_name = objId[set[2]].name_option
        }

        if (objId[set[0]] && objId[set[0]].name_type !== undefined) {
          item.first_attribute_name_type = objId[set[0]].name_type
        }

        if (objId[set[1]] && objId[set[1]].name_type !== undefined) {
          item.second_attribute_name_type = objId[set[1]].name_type
        }

        if (objId[set[2]] && objId[set[2]].name_type !== undefined) {
          item.third_attribute_name_type = objId[set[2]].name_type
        }
        array.push(item)
      })
      this.sortByKey(array, 'second_attribute_name')
      this.sortByKey(array, 'first_attribute_name')
      return array
    },
    async handlerFilterAttribute() {
      // First Attribute
      this.firstAttributeOptions = []
      this.firstAttributeOptionsTemp.map(val => {
        if (this.listVariant_1.filter(x => x.name === val.name).length <= 0) {
          this.firstAttributeOptions.push(val)
        }
      })

      // Second Attribute
      this.secondAttributeOptions = []
      this.secondAttributeOptionsTemp.map(val => {
        if (this.listVariant_2.filter(x => x.name === val.name).length <= 0) {
          this.secondAttributeOptions.push(val)
        }
      })

      // Third Attribute
      this.thirdAttributeOptions = []
      this.thirdAttributeOptionsTemp.map(val => {
        if (this.listVariant_3.filter(x => x.name === val.name).length <= 0) {
          this.thirdAttributeOptions.push(val)
        }
      })
    },
    async handleChooseVariation(number) {
      if (number === 1) {
        this.listVariant_1 = []
        this.listVariant_1.push('')
        this.firstAttributeOptions = this.ware_house_temp[0].list
        this.firstAttributeOptionsTemp = this.ware_house_temp[0].list
        this.handlerShowTableVariation()
      }
      if (number === 2) {
        this.listVariant_2 = []
        this.listVariant_2.push('')
        this.secondAttributeOptions = this.ware_house_temp[1].list
        this.secondAttributeOptionsTemp = this.ware_house_temp[1].list
        this.handlerShowTableVariation()
      }
      if (number === 3) {
        this.listVariant_3 = []
        this.listVariant_3.push('')
        this.thirdAttributeOptions = this.ware_house_temp[2].list
        this.thirdAttributeOptionsTemp = this.ware_house_temp[2].list
        this.handlerShowTableVariation()
      }
    },
    async loadFirstAttributeList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/first_attribute/tree?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.firstAttributeList = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadSecondAttributeList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/second_attribute/tree?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.secondAttributeList = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadThirdAttributeList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/third_attribute/tree?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.thirdAttributeList = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
button svg {
  margin-right: 0 !important;
}
.edit-main {
  // flex-basis: 770px;
  // width: 770px;
  // max-width: 770px;
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.table-header {
  background: #fafafa;
}
.first-variation {
    // -webkit-box-flex: 1;
    // -ms-flex: 1;
    // flex: 1;
    // margin-right: -1px;
  width: 100px;
}
.table-cell {
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #999;
  border: 1px solid #ebebeb;
  border-bottom-width: 0;
  border-right-width: 0;
  word-break: break-word;
}

.wpx-100 {
  width: 11rem;
  @media screen and (max-width: 1699px) {
    width: 10rem;
  }
  @media screen and (max-width: 1440px) {
    width: 8rem;
    min-width: 8rem
  }
  @media screen and (max-width: 1399px) {
    width: 7.5rem;
    min-width: 5rem
  }
  @media screen and (max-width: 1299px) {
    width: 7rem;
    min-width: 5rem
  }
}
.wpx-150 {
  width: 17rem;
  @media screen and (max-width: 1440px) {
    width: 11rem;
  }
}
.input-contrl {
  border-color: #ffffff;
  border-radius: 0;
  text-align: center;
}
.bradius-br {
  border-bottom-width: thin;
  border-right-width: thin;
}
</style>
