<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-form-group :label="`*`+$t('Photos for Variation') +`:`" label-cols-md="12">
    <b-col v-if="value.ware_house && value.ware_house.length > 0" md="9">
      <b-row class="mb-2">
        <b-col
          v-for="(item, index) in value.ware_house"
          :key="index"
          md="3"
          class="align-items-center justify-content-center text-center mb-2"
        >
          <div
            class="fade-browse w-100 text-center d-flex align-items-center justify-content-center img-ctrl"
          >
            <b-img-lazy
              :src="item.avatar"
              fluid
              thumbnail
              rounded
              class="ctrl-img1 variant-avt"
            />
            <div class="variant-avt-ctrl">
              <div class="ctrl-bd">
                <b-form-file
                  :id="`input-product-${item.name_temp}`"
                  multiple
                  accept=".jpg, .png, .gif, .jpeg"
                  class="img-box-variation"
                  @change="previewFiles($event, index)"
                />
                <label
                  :for="`input-product-${item.name_temp}`"
                  class="btn-add-variation"
                >
                  <feather-icon icon="UploadCloudIcon" />
                  Upload
                </label>
              </div>
              <label class="btn-add-variation">
                <feather-icon icon="PlusCircleIcon" />
                Media center
              </label>
            </div>
          </div>
          <div class="mt-1">
            <span> {{ item.first_attribute_name }} </span>
            <span v-if="item.second_attribute_name !== undefined">
              - {{ item.second_attribute_name }}
            </span>
            <span v-if="item.third_attribute_name !== undefined">
              - {{ item.third_attribute_name }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-form-group>
</template>
<script>
/* eslint-disable object-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
import { BFormGroup, BRow, BCol, BFormFile, BImgLazy } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BImgLazy,
    BFormGroup,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  // mounted() {
  //   this.value.ware_house.map((x, xdex) => {
  //     x.seccond_attribute.options.map((y, ydex) => {
  //       this.avatar[`img_${xdex}-${ydex}`] = null
  //     })
  //   })
  // },
  methods: {
    previewFiles(input, index) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.value.ware_house[index].avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.alolo {
  position: relative;
  &:hover {
    .review-img__del {
      opacity: 1;
    }
  }
}
.review-img {
  position: relative;

  &::before {
    position: absolute;
    content: 'Drag to Sort';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(100, 100, 100, 0.75);
    left: 1rem;
    right: 1rem;
    bottom: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  &__del {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before,
    .review-img__del {
      opacity: 1;
    }
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  min-height: 155px;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  &:hover .variant-avt-ctrl {
    opacity: 1 !important;
  }
  .ctrl-img1 {
    position: absolute;
    object-fit: contain;
    // z-index: 1;
  }
  .variant-avt {
    &[src] {
      + .variant-avt-ctrl {
        opacity: 0;
      }
    }
    &-ctrl {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(20px);
      transition: all 250ms;
      opacity: 1;
    }
  }
  .ctrl-bd {
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .btn-add-variation {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
  .img-box {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 1rem;
    right: 1rem;
    width: 0;
  }
  .img-box-variation {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 1rem;
    right: 1rem;
    width: 0;
  }
}
</style>
